// extracted by mini-css-extract-plugin
export var whyWeSection = "WhyWeSection-module--whyWeSection--QaREx";
export var infoTitle = "WhyWeSection-module--infoTitle--mBCs+";
export var title = "WhyWeSection-module--title--KNGLV";
export var description = "WhyWeSection-module--description--+vlim";
export var detailsSection = "WhyWeSection-module--detailsSection--nV-Qu";
export var paragraph = "WhyWeSection-module--paragraph--pGfd1";
export var illustration = "WhyWeSection-module--illustration--jf3ZZ";
export var root = "WhyWeSection-module--root--yhpgy";
export var titleInfo = "WhyWeSection-module--titleInfo--RCx6v";
export var detilsSection = "WhyWeSection-module--detilsSection--zhdKl";